
.privacy-policy {
    background-color: #632a77;
}
.privacy-policy .right-side {
    padding: 40px 0;
    text-align: center;
}
.privacy-policy .right-side-text {
    padding-top: 100px;
}
.privacy-policy .right-side h1 {
    color: #f0dd05;
}
.privacy-policy .right-side p {
    font-size: 18px;
    color: #fff;
    line-height: 1.9;
}
.privacy-policy-content {
    padding: 100px 15px 130px;
}
.privacy-policy-content p.sub-title {    
    font-size: 22px;
    font-weight: 700;    
    color: #632a77;
}