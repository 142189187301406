.services-page-why-work-with-us {
	background-color: #2e9044;
}
.services-page-why-work-with-us>.row{
  background: url(/assets/images/services/img-happy-girl-design.png) no-repeat;
  background-position: left top;
}
.spwwu-box {
	padding: 170px 110px 257px 145px;
}
.spwwu-box>h1 {
	color: #fff
}
.spwwu-box>h1>span {
  font-weight: 700;
  color: #f0dc03;
}
.spwwu-box>p {
  color: #fff;
}
.spwwu-box>h4.tag-line {
  font-weight: 700;
  color: #fff;
}
.spwwu-box>h4.tag-line>span {
  color: #f0dc03;
}
.services-page-our-services {
	padding: 65px 0 135px;
}
.services-page-our-services>h1,
.services-page-our-services>p {
	text-align: center;
}
.services-page-our-services>h1>span,
.services-page-our-services>p>span {
  color: #268bd0;
  font-weight: 700;
}
.width-66 {
	width: 66px;
}
.flex {
	display: flex;
	align-items: center;
}
.flex span {
	padding-right: 10px;
}
.flex-right {
    padding-left: 75px;
    display: flex;
    min-height: 200px;
    justify-content: space-between;
    align-content: stretch;
    flex-wrap: wrap;
    flex-direction: column;
}
.flex-right>a {
  position: absolute;
  bottom: 85px;
}
.service-box {
	border: 1px solid #c8c8c8;
	border-radius: 5px;
	padding: 50px 25px;
	margin-bottom: 50px;
	min-height: 350px;
}