.about-page-shaping-the-future {
  background-color: #2689cf;
  color: #fff;
  position: relative;
}
.about-page-shaping-the-future>.row{
  background: url(/assets/images/about/img-girl-help-splatter.png) no-repeat;
  background-position: right bottom;
}
.apstf-box {
  padding: 110px 0 130px;
  float: right;
}
.apstf-box>h1>span {
  font-weight: 700;
}

.about-page-our-team {
  padding: 65px 65px 135px;
  text-align: center;
}
.about-page-our-team>h1 {
}
.about-page-our-team>h1>span,
.about-page-our-team>p>span {
  color: #268bd0;
  font-weight: 700
}

.about-page-we-make {
  background-color: #2e9044;
  color: #fff;
  position: relative;
  height: 602px;
}
.about-page-we-make img {
  margin-top: -137px;
  margin-left: -200px;
}
.apwk-box {
  padding: 167px 30px 0 0px;
}
.apwk-box>p {
  /*font-size: 20px;*/
}