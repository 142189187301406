.fast-and-secure-hosting {
    background-color: #209044;
}
.fast-and-secure-hosting .left-side {
    padding: 133px 0 50px;
    text-align: center;
}
.fast-and-secure-hosting .left-side .left-side-text {
    color: #fff;
}
.fast-and-secure-hosting .left-side .left-side-text span {
    color: #e6d000;
}
.fast-and-secure-hosting .right-side img {
    margin-bottom: -1px;
}
/*  */
.dedicated-hosting {
    padding-top: 80px;
}
.dedicated-hosting .hosting-text {
    padding-bottom: 20px;
}
.dedicated-hosting .left-side table {
    box-shadow: 3px 5px 11px #888888;
    /* width: 415px!important; */
    float: right;
}
.dedicated-hosting .left-side table td {
    font-size: 14px;
}
.dedicated-hosting .left-side table thead th {
    background-color: #1e9144!important;
    color: #fff!important;
    padding: 5px;
}
.dedicated-hosting .left-side table thead th,
.dedicated-hosting .left-side table tbody td {
    text-align: center;
}
.dedicated-hosting .left-side table tbody td {
    padding: 7px 5px;
}
.dedicated-hosting .right-side img {
    position: absolute;
    bottom: -4px;
    width: 445px;
}
.shared-hosting {
    padding-top: 80px;
    padding-bottom: 80px;
}
.shared-hosting .hosting-text {
    padding-bottom: 20px;
}
.shared-hosting .right-side table {
    box-shadow: 3px 5px 11px #888888;
    /* width: 415px!important; */
}
.shared-hosting .right-side table td {
    font-size: 14px;
}
.shared-hosting .right-side table thead th {
    background-color: #1e9144!important;
    color: #fff!important;
    padding: 5px;
}
.shared-hosting .right-side table thead th,
.shared-hosting .right-side table tbody td {
    text-align: center;
}
.shared-hosting .right-side table tbody td {
    padding: 7px 5px;
}
.shared-hosting .left-side img {
    position: absolute;
    bottom: 15px;
    width: 505px;
    right: 5px;
}

.ready-to-get-started {
    background-color: #2789d0;
    padding: 0 0;
    color: #fff;
    height: 625px;
}
.ready-to-get-started-fluid {
    padding: 82px 0 0;
    background-image: url(/assets/images/services/hosting/img-rocket.png);
    color: #fff;
    background-size: cover;
    position: relative;
    transform: translateY(-2%);
}
.ready-to-get-started-box {
    height: 556px;
}
.ready-to-get-started .ready-to-get-text h1 {
    margin-bottom: 60px;
    margin-top: 15px;
}
.ready-to-get-started .choose-your-plan {
    margin: 140px 0 40px;
    color: #2789d0;
    padding-left: 20%;
}
.ready-to-get-started .choose-your-plan h3 {
    font-weight: bold;
}
.ready-to-get-started .choose-your-plan select {
    border: 2px solid #2789d0;
    color: #2789d0;
    box-shadow: none;
}
.ready-to-get-started .choose-your-plan .btn-get-it{
    padding: 0.375rem 71px;
    font-weight: bold;
}
.ready-to-get-started .let-us-know {
    color: #000;
}
.ready-to-get-started .btn-let-us-know {
    color: #fff;
    background-color: #2789d0;
    padding: 0.375rem 30px;
    font-weight: bold;
}
.ready-to-get-started .btn-let-us-know:hover {
    background-color: #1e6ea9;
}
.what-is-web-hosting {
    background-image: linear-gradient(#90e0fb, #dbf7fa);
    padding: 80px 15px 100px;
}
.what-is-web-hosting h2 {
    margin-bottom: 30px;
}
.what-is-web-hosting h2 span {
    font-weight: bold;
    color: #2888d2;
}
.text-bold {
    font-weight: bold;
}
.w-60px {
    width: 60px;
}
.w-70px {
    width: 70px;
}
.w-123 {
    width: 123%!important;
}
.w-125px {
    width: 125px;
}

@media (max-width: 980px) {
    /* Services */
    /* Services - Hosting */
    .ready-to-get-started .ready-to-get-text h1 {
        margin-bottom: 30px;
    }
    .ready-to-get-started .choose-your-plan {
        margin: 40px 0 130px;
        padding-left: 0;
        color: #fff;
    }
    .ready-to-get-started-box {
        height: 100%;
    }
    .ready-to-get-started {
        height: 750px;
    }
    .ready-to-get-started-fluid {
        background-image: url(/assets/images/services/hosting/img-rocket.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom;
        padding: 82px 0 40px;
    }
}

@media (max-width: 760px) {
    /* Services */
    /* Services - Hosting */
    .ready-to-get-started .choose-your-plan {
        margin: 40px 0 40px;
    }
    .ready-to-get-started-fluid {
        background-image: none;
        /* padding: 82px 0 40px; */
    }
    .ready-to-get-started {
        height: 100%;
    }
    .ready-to-get-started .btn-let-us-know {
        background-color: #064674;
    }
    .ready-to-get-started .let-us-know {
        color: #fff;
    }
}