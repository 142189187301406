.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.width-35 {
  width: 35px;
}
.sticky-top {
  background: url(/assets/images/rainbow-bar.jpg) top repeat-x rgba(245,245,245,0.92) !important;
  /*margin-top: 4px;*/
}
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
  color: #2689cf;
}
.sticky-top.navbar-light .navbar-nav .nav-link {
  padding: 1.4rem 1rem 1rem;
  font-weight: 600;
}
.sticky-top.navbar-light .navbar-brand {

}
.sticky-bottom.navbar-dark .navbar-nav .nav-link {
  padding: 1rem 1rem;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
}
.loader {
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}
.footer-strip {
  background-color: #dadada; 
}
.footer-strip img {
  margin-top: -80px;
  position: relative;
}
.text-skyblue {
  color: #2689cf;
}
.text-green {
  color: #2e9044;
}
.text-yellow {
  color: #f0dd02;
}
.text-black {
  color: #313131;
}
.w-15-i {
  width: 15px!important;
}
.bg-yellow {
  background-color: #f0dd02;
}
.mb-less-45 {
    margin-bottom: -45px;
}

/*********** HOME ***********/
.home-carousel .carousel-caption {
  top: 25%!important;
}

.home-carousel .carousel-caption p {
  padding-top: 15px;
  font-size: 20px;
}

.grow-your-business {
  padding: 80px 30px;
  background-color: #fff;
}

.featured-services {
  padding: 80px 30px;
  background-color: #2e9044;
}
.featured-services img {
  position: relative;
  z-index: 1;
  border: 10px solid #2e9044;
  border-radius: 50%;
}
.featured-services a.learn-more {
  color: #fff;
  border: 2px solid #fff;
  padding: 5px 20px;
  border-radius: 5px;
  text-transform: uppercase;
}
.featured-services a.learn-more>span {
  margin-right: 10px;
}
.featured-services a.learn-more:hover,
.featured-services a.learn-more:focus {
  text-decoration: none;
  background: #237134;
  border: 2px solid #1a5627;
}
.featured-services .line-bg::after {
  content: "----------------------------------------------------------------------------------------------------------------";
  position: absolute;
  top: 40%;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 0;
}

.how-it-works {
  padding: 80px 30px 120px;
  background-color: #fff;
}
.how-it-works img.thumbnail {
  width: inherit;
}
.how-it-works .img-number {
  padding-top: 125px;
}

.btn-get-a-q {
  padding: 8px 13px;
  color: #000;
  border-radius: 5px;
  text-transform: uppercase;
  border-bottom: 4px solid #d4a205;
  border-right: 3px solid #d4a205;
}

.btn-get-a-q:hover {
  background-color: #2689cf!important;
  color: #fff;
  border-bottom: 4px solid #1a5d8c;
  border-right: 3px solid #1a5d8c;
}

.display-none-lg {
  display: none;
}
.overflow-hidden {
  overflow: hidden;
} 

/* 
  TEXT SIZES
*/
.text-14 {
  font-size: 14px;
}
/* 
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  
}

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {

}

/* 
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

}

@media (max-width: 767px) {
  .display-none-lg {
    display: block;
  }
  .display-none-sm {
    display: none;
  }
  .grow-your-business .img-grow-your-business {
    max-width: 100%;
    width: inherit;
    float: none!important;
  }
  .grow-your-business .text-grow-your-business {
    font-size: 1.5rem;
    text-align: center;
    padding-bottom: 1.5rem!important;
  }
  .grow-your-business .grow-your-business-right-box p {
  text-align: justify;
  }
  .featured-services .line-bg::after {
    all: unset;
  }
  .featured-services .col-feature {
    margin-bottom: 75px;
  }
  .how-it-works .img-number {
    padding-top: 0px;
  }
  .how-it-works .img-number>h2 {
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
  }
  .how-it-works .img-number>img {
    margin-top: -180px;
  }
  .about-page-shaping-the-future>.row {
    background: none!important;
  }
  .about-page-shaping-the-future .img-about-page-shaping-the-future {
    max-width: 100%;
    width: inherit;
    float: none!important;
  }
  .apwk-box {
    padding: 60px 0 130px!important;
  }
  .apstf-box {
    padding: 65px 0!important;
    float: none!important;
  }
  .apstf-box h1 {
    font-size: 1.5rem!important;
    padding-bottom: 1rem!important;
  }
  .about-page-we-make {
    height: 100%!important;
  }
  .about-page-we-make img {
    margin-top: 0;
    margin-left: 0;
    max-width: 100%;
    width: inherit;
    float: none!important;
  }

  .services-page-why-work-with-us>.row {
    background: none!important;
  }
  .services-page-why-work-with-us img {
    max-width: 100%;
    width: inherit;
    float: none!important;
    margin-bottom: -74px;
  }
  .spwwu-box {
    padding: 65px 15px!important;
    text-align: center;
  }
  .spwwu-box>h1 {
    font-size: 1.8rem;
  }
  .services-page-our-services {
    padding: 65px 15px 83px!important;
    width: 100%;
    max-width: max-content;
  }
  .service-box {
    padding: 25px 15px 15px!important;
    min-height: auto!important;
    text-align: center!important;
  }
  .service-box .flex {    
    display: block;
  }
  .service-box .flex>p{
    padding-top: 20px;
  }
  .service-box .flex-right {
    padding-left: 0!important;
  }

  .quotation-page {
    padding: 65px 15px 100px!important;
    max-width: max-content!important;
  }
}

/* 
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
}