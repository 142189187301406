.cancellation-policy {
    background-color: #233f88;
}
.cancellation-policy .left-side {
    padding: 83px 0;
    text-align: center;
}
.cancellation-policy .left-side h1 {
    color: #f0dd05;
}
.cancellation-policy .left-side p {
    font-size: 18px;
    color: #fff;
    line-height: 1.9;
}

.cancellation-policy-tabs {
    margin: 80px auto 130px;
    border: 1px solid #b7b7b7;
    padding: 0;
    box-shadow: 0px 13px 11px -10px #000;
    max-width: 970px;
}
.cancellation-policy-tabs .nav-tabs .nav-link {
    border: 0;
    border-radius: 0;
    color: #233f88;
    font-weight: 600;
    border-left: 1px solid;
    border-color: #b7b7b7 #e9ecef #dee2e6;
}
.cancellation-policy-tabs .nav-tabs .nav-item.show .nav-link,
.cancellation-policy-tabs .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #233f88;
    border-color: transparent;
    border: 0;
    border-radius: 0;
    font-weight: 600;
}
.cancellation-policy-tabs .tab-content {
    padding: 0px 15px;
}

.cancellation-policy-tabs .tab-content p.sub-title {
    font-weight: 700;    
    font-size: 18px;
    color: #233f88;
}